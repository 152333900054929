<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >公司名称 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.companyName"
                  placeholder="请输入"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >用户姓名 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >电话 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <!-- <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >职位 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.post"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div> -->
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >申请状态 :
              </span>
              <div class="width300">
                <el-select
                  class="width300"
                  v-model="searchData.state"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in OOrderStatus"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >申请时间 :
              </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :clearable="true"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <!-- <template slot="title" slot-scope="{ option }">
        <div class="custom-title">
          <span>自定义标题</span>
          <span class="total">总数：{{ option.total }}</span>
        </div>
      </template> -->
      <template slot="state" slot-scope="{ row }">
        {{ row.state == 1 ? "待处理" : row.state == 2 ? "已处理" : "" }}
      </template>
      <template slot="menu" slot-scope="{ row }">
        <!-- <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
          >详情</el-button
        > -->
        <el-button
          type="text"
          size="mini"
          @click="handleEdit(row)"
          v-if="row.state == 1"
          >审核</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      title="修改状态"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="state">
          <el-select
            class="width250"
            v-model="form.state"
            clearable
            placeholder="请选择"
            style="width: 300px !important"
          >
            <el-option
              v-for="item in OOrderStatus"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">
            {{ isDetails ? "关闭" : "取消" }}</el-button
          >
          <el-button
            type="primary"
            @click="submit(form)"
            :loading="showLoadingForm"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { joinExamineList, joinExamineStateUpdate } from "@/api/join/examine";
import { mapGetters } from "vuex";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import {
  EApplicationStatus,
  MOrderStatus,
  OOrderStatus,
} from "@/enums/beCurrent/EBeCurrent";
import dayjs from "dayjs";

export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  // computed: {
  // 	...mapGetters(['getButton'])
  // },
  data() {
    return {
      EApplicationStatus: EApplicationStatus,
      MOrderStatus: MOrderStatus,
      OOrderStatus: OOrderStatus,
      button: {},
      showAuthority: true,
      isDetails: false,
      typeList: [],
      entryList: [],
      searchData: {
        companyName: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      showLoadingForm: false, //防重提交
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "申请用户姓名",
            prop: "username",
          },
          {
            label: "电话",
            prop: "phone",
          },
          {
            label: "公司名称",
            prop: "companyName",
          },
          // {
          //   label: "职位",
          //   prop: "post",
          // },
          {
            label: "状态",
            prop: "state",
          },
          {
            label: "申请时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      form: {
        state: "",
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "处理状态",
            prop: "state",
            type: "text",
            maxlength: 100,
            span: 24,
            rules: [
              {
                required: true,
                message: "",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      dialogPassWordVisible: false,
      dataUrl: "",
    };
  },
  created() {},
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchForm.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchForm.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchForm.createTime;
      this.showLoading = true;
      joinExamineList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchForm,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      // localStorage.removeItem("searchForm");
      this.searchData = {
        companyName: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    //审核 通过
    async handleEdit(row) {
      this.$confirm("确定审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        joinExamineStateUpdate({
          id: row.id,
          state: 2,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.onLoad();
            }
          })
          .catch();
      });

      // this.isDetails = false;
      // this.form.state = Number(row.state);
      // this.form.id = row.id;
      // this.dialogVisible = true;
    },

    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    // 提交
    submit(form) {
      this.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          joinExamineStateUpdate({
            ...form,
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.showLoadingForm = false;
                this.onLoad();
              }
            })
            .catch(() => (this.showLoadingForm = false));
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>
